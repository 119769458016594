import "../assets/styles/HeroDisplay.scss";

const VideoDisplay = ({ heading, featureList, path, imageStyle }) => {
    let tickIcon = require("../assets/images/icons/Tick.png");
    return (
        <>
            {/* <div className="triangle-down"></div> */}
            <div className="hero-display">
                <section className="body-section">
                    <div className="pannel-hero">
                        <div className={imageStyle}>
                            <video
                                src={path}
                                width="400"
                                height="400"
                                autoPlay
                                muted
                                loop={true}
                            ></video>
                        </div>
                    </div>

                    <div className="pannel-hero">
                        <div className="product-text">
                            <h2 className="sub-heading">{heading}</h2>
                            {featureList.map((item, key) => (
                                <div key={key}>
                                    <img src={tickIcon} alt="Tick icon" />
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default VideoDisplay;
