const ProjectDetails = [
    {
        title: "Mask Link",
        image: require("../assets/images/icons/MaskLink_192.png"),
        altText: "Mask Link app preview",
        blurb: "Start using Mask Link to update all of your render masks in one click",
        buttonText: "Plugin for Adobe Photoshop",
        links: "/masklink",
    },
    {
        title: "Smart Builder",
        image: require("../assets/images/icons/SmartBuilder_192.png"),
        altText: "Smart Builder app preview",
        blurb: "Adobe Photoshop automation with AWS Thinkbox Deadline",
        buttonText: "Deadline/Photoshop connection",
        links: "/smartbuilder",
    },
];

export default ProjectDetails;

// http://www.jakehill.au/masklink
