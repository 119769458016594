import "../assets/styles/TitleProduct.scss";
// import maskLinkIcon from "../../assets/images/icons/MaskLink.png";
import Button from "../components/Button";

const TitleProduct = ({
    icon,
    title,
    subTitle,
    smallText,
    actionButton,
    image,
    imageStyle,
    // data: { text, link, newTab },
}) => {
    return (
        <div className="container">
            <div className="body-section">
                <div className="title-product">
                    <h1 className="heading" id="home">
                        <span className="important">{title}</span>
                    </h1>
                    <h1>{subTitle}</h1>
                    <div className="logo">
                        <img src={icon} alt="Product Icon" />
                        <p>{smallText}</p>
                    </div>
                    <div className="buttons">
                        <Button {...actionButton} />
                    </div>
                </div>
                <div className={imageStyle}>
                    <img src={image} alt="Product screenshot" />
                </div>
            </div>
        </div>
    );
};

export default TitleProduct;
