import "../assets/styles/nav-bar.scss";

const NavBar = ({ route }) => {
    return (
        <nav className="nav-contianer">
            <ul className="nav-bar">
                <li>
                    <a href="/" className={route === "/" ? "active" : ""}>
                        Home
                    </a>
                </li>

                <li>
                    <a
                        href="/masklink"
                        className={route === "/masklink" ? "active" : ""}
                    >
                        MaskLink
                    </a>
                </li>
                <li>
                    <a
                        href="/smartbuilder"
                        className={route === "/smartbuilder" ? "active" : ""}
                    >
                        SmartBuilder
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default NavBar;
