import "../assets/styles/Projects.scss";
import ProjectCard from "../components/ProjectCard";
import ProjectDetails from "../components/ProjectDetails";

const Project = () => {
    return (
        <div className="projects">
            <h1 className="heading" id="projects">
                3D Productivity Tools
            </h1>
            <p>Click to view more details of my current tools.</p>
            <section className="body-section">
                <div className="card-container">
                    {ProjectDetails.map((project, i) => {
                        return <ProjectCard key={i} ProjectDetails={project} />;
                    })}
                </div>
            </section>
        </div>
    );
};

export default Project;
