// import "./ProjectCard.scss";

const ProjectCard = ({ ProjectDetails }) => {
    return (
        <div className="card">
            <a href={ProjectDetails.links}>
                <div className="card-thumbnail">
                    <img
                        src={ProjectDetails.image}
                        alt={ProjectDetails.altText}
                        className="card-image"
                    />
                </div>
                <h4 className="card-title">{ProjectDetails.title}</h4>
                <p className="blurb">{ProjectDetails.blurb}</p>
                <p className="button-text">{ProjectDetails.buttonText}</p>
                <div className="buttons">
                    <div className="button-fake">Learn More</div>
                </div>
            </a>
        </div>
    );
};

export default ProjectCard;
//"_blank"
