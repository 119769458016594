import NavBar from "../features/nav-bar";
import TitleHome from "../features/TitleHome";
import About from "../features/About";
import Projects from "../features/Projects";
import Contact from "../features/Contact";
// import "./Homepage.scss";

function Homepage() {
    return (
        <div className="App">
            <NavBar route={"/"} />
            <TitleHome />
            <Projects />
            <About />
            <Contact />
        </div>
    );
}

export default Homepage;
