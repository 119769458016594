import "../assets/styles/HeroDisplay.scss";
import HowPannel from "../components/HowPannel";

const HowItWorks = ({ howDetails }) => {
    return (
        <div className="hero-display">
            <section className="body-section">
                <div className="pannel-hero">
                    <h1 className="heading">How it works?</h1>
                    {howDetails.map((details, i) => {
                        return <HowPannel key={i} details={details} />;
                    })}
                </div>
            </section>

            {/* <div className="triangle-down"></div> */}
        </div>
    );
};

export default HowItWorks;
