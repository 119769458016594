import "../assets/styles/HowPannel.scss";

const HowPannel = ({ details }) => {
    return (
        <section className="body-section">
            <div className="pannel">
                <hr />
                <h2 className="heading">{details.title}</h2>
                <h3 className="product-text">{details.blurb}</h3>
                {details.image !== null ? (
                    <div className="screenshot-image">
                        <img src={details.image} alt={details.altText} />
                    </div>
                ) : details.video !== null ? (
                    <div>
                        <iframe
                            src={details.video}
                            // width="750"
                            // height="562"
                            className="video"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen="true"
                        ></iframe>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
        </section>
    );
};

export default HowPannel;
