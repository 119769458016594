import NavBar from "../features/nav-bar";
import TitleProduct from "../features/TitleProduct";
// import About from "../../components/About";
import HeroDisplay from "../features/HeroDisplay";
import VideoDisplay from "../features/VideoDisplay";
import VideoDisplayFlipped from "../features/VideoDisplayFlipped";
import Contact from "../features/Contact";
import HowItWorks from "../features/HowItWorks";
import HowMaskLink from "../components/HowMaskLinkDetails";
import Testimonials from "../features/Testimonials";

function MaskLink() {
    // Title details
    let icon = require("../assets/images/icons/MaskLink_192.png");
    let title = "Mask Link Free";
    let subTitle = "Save time by updating all 3D render masks in one click!";
    let smallText = "Available for Adobe Photoshop";
    let actionButton = {
        text: "Download Now",
        link: "https://exchange.adobe.com/apps/cc/6ffd3873/mask-link-free",
        // link: "https://drive.google.com/uc?export=download&id=1x4PRUqyOtlzF5Jc8jN7L4gckyUTzYdxA",
        newTab: true,
    };
    // Hero details
    let image = require("../assets/images/masklink/Hero_Free_Sharp.png");
    let imageGraphic = require("../assets/images/masklink/Update_Masks_Graphic.png");
    let video1 = "assets/video/MaskLink_Selection_Website.mp4";
    let video2 = "assets/video/Web_Edit_Buttons.mp4";
    let imageStyle = "product-image";
    let imageStyleGraphic = "graphic-image";
    let featureList = [
        "Add masks with links",
        "Identify updated renders",
        "Select layers with links",
        "Cut, copy, paste, invert masks",
        "Update all masks",
    ];
    let selectionList = [
        "Select Links (highlight Layers)",
        "Select Layers (highlight Links)",
        "Select All Layers Button",
    ];
    let editList = ["Cut", "Copy", "Paste", "Paste Multiple", "Invert"];
    return (
        <div className="App">
            <NavBar route={"/masklink"} />
            <TitleProduct
                icon={icon}
                title={title}
                subTitle={subTitle}
                smallText={smallText}
                actionButton={actionButton}
                image={image}
                imageStyle={imageStyle}
            />
            <HeroDisplay
                title={"Update all render masks"}
                featureList={featureList}
                image={imageGraphic}
                imageStyle={imageStyleGraphic}
            />
            <VideoDisplay
                heading={"Edit mask features"}
                featureList={editList}
                path={video2}
                imageStyle={imageStyleGraphic}
            />
            <VideoDisplayFlipped
                heading={"Interactive selection"}
                featureList={selectionList}
                path={video1}
                imageStyle={imageStyleGraphic}
            />

            <Testimonials />
            <HowItWorks howDetails={HowMaskLink} />
            {/* <About /> */}
            <Contact />
        </div>
    );
}

export default MaskLink;
