const Button = ({ text, link, newTab }) => {
    return (
        <a
            href={link}
            target={newTab ? "_blank" : ""}
            rel="noopener noreferrer"
        >
            <button className="button-large">{text}</button>
        </a>
    );
};

export default Button;
