import "../assets/styles/Contact.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/Contact-form";

const Contact = () => {
    return (
        <div className="contact">
            <h1 className="heading" id="contact">
                Contact
            </h1>
            <section className="body-section">
                {/* <div className="info">
                    <div className="icon-list">
                        <a
                            href="https://www.linkedin.com/in/jake-hill-5677191b5/"
                            className="icon"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faLinkedin} />
                            <p>LinkedIn</p>
                        </a>
                        <a
                            href="mailto:jakemelb87@gmail.com"
                            className="icon"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                            <p>Email</p>
                        </a>
                    </div>
                    <p className="paragraph">
                        For more information see my LinkedIn or GitHub page. If
                        you would like to get in touch, please send me a message
                        through this contact form.
                    </p>
                </div> */}

                <div className="info">
                    {/* <p>info.jakehill@gmail.com</p> */}
                    <div className="icon-list">
                        <a
                            href="https://www.linkedin.com/in/jake-hill-5677191b5/"
                            className="icon"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a
                            href="mailto:info.jakehill@gmail.com"
                            className="icon"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </div>

                    {/* TODO fix contact form */}
                    <ContactForm />
                </div>
            </section>
        </div>
    );
};

export default Contact;
